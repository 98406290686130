import '@/types/global' //register global windows
import '@/styles/global.scss'

import { AppProps } from 'next/app'
import {DatabaseProvider, GlobalProvider} from '@/services/providers'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ClerkProvider,   SignedIn, SignedOut,SignIn, UserButton, } from "@clerk/nextjs";
import { dark } from "@clerk/themes";
import { useRouter } from 'next/router'
import Layout from '@/components/Layouts';

import { Box } from '@mui/material';


export default function MyApp({
  Component,
  pageProps,
}: AppProps) {

  const clerkSetting = {
    layout: {logoImageUrl: "./logo72x72.png"},  baseTheme: dark, 
    signIn: { 
      variables: { colorPrimary: 'black',colorBackground: "#272727" }, 
      elements: {  
        logoBox: {display: "none"},
        headerSubtitle: {display: "none" },
        formResendCodeLink: { color: "rgba(255,255,255,0.5)" },
        footer: {display: "none"}
      },
    }
  }

  const router = useRouter()
  const redirectTo = !!router && router.asPath != "/" ? router.asPath : "/secure/dashboard"

  return (
    <>
    <ClerkProvider  {...pageProps}
        afterSignInUrl="/secure/dashboard"
        appearance={clerkSetting}>
            <DatabaseProvider>
              <GlobalProvider>
               
                    <SignedIn>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Component {...pageProps} />                         
                          </LocalizationProvider>
                      </SignedIn>

                <SignedOut>
                    <Layout >
                    <Box width={"100%"} justifyContent={"center"} display={"flex"} alignContent={"center"} flexWrap={"wrap"}>
                        <SignIn redirectUrl={redirectTo}  />
                    </Box>

                    </Layout>
                </SignedOut>

          
              </GlobalProvider>
            </DatabaseProvider>

        </ClerkProvider>    
    </>
  )
}
 