"use client"
import React, { useContext, useEffect } from "react";
import  {db, IGroup, IStat} from  '@/services/db'
import {uid} from 'uid'

import  {format, subMonths, parse} from "date-fns";
import CryptoES from 'crypto-es';


interface DatabaseContext {
  group : {
    add : (obj:IGroup) => Promise<void>,
    update : (obj:IGroup) => Promise<void>,
    delete : (id: string) => Promise<void>,
    data: () => Promise<IGroup[]>,
    default: () => IGroup
  },
  stats : {
    add : (obj:IStat) => Promise<void>,
    find: (hash:string) => Promise<IStat[]>
    delete : (id: string) => Promise<void>,
    data: () => Promise<IStat[]>,
    default: () => IStat
  }  
}

// Define the Provider component
interface ProviderProps {
  children: React.ReactNode;
}

// Create the AuthContext
const DatabaseContext = React.createContext<DatabaseContext | undefined>(
  undefined
);
 
const DatabaseProvider = (props: ProviderProps) => {
  const group = {
    add : async (obj:IGroup) => {
      await db.group.add({...obj, id: uid(12) })
    },
    update : async (obj:IGroup) => {
      await db.group.update(obj.id,  {
        name: obj.name,
        description: obj.description
      })
    },
    delete : async (id:string) => {
      await db.group.delete(id)
    },
    data:  async () => db.group.toArray(),
    default : ():IGroup => {
      return {
        id: "", name: "", description: ""
      } 
    } 
  }
  const stats = {
    add : async (obj:IStat) => {
      const hash = CryptoES.HmacSHA1([obj.date, obj.draw_number + obj.position].join('|'), 'patgauparser').toString(CryptoES.enc.Base64url)
      await db.stats.add({...obj, id: uid(12) })
    },
    delete : async (id:string) => {
      await db.stats.delete(id)
    },
    find: async (hash:string) => db.stats.where('hash').equals(hash).toArray(),
    data:  async () => { 
      return db.stats.where('date').between(subMonths(new Date(), 6), new Date(), true, true).toArray() 
    },
    default : ():IStat => {
      return {
        id: "",
        hash: "",
        position:0,
        name: "",
        group: "",
        date: parse(format(new Date(), 'yyyy-MM-dd 00:00:00'), 'yyyy-MM-dd HH:mm:ss', new Date()),
        draw_type: "Main", 
        draw_number: "Main", 
        draw_name: "",
        price: 0        
        
      } 
    } 
  }




  return (
    <DatabaseContext.Provider
      value={{
        group: group,
        stats: stats
      }}
    >
      
        {props.children}
    </DatabaseContext.Provider>
  );
}
export default DatabaseProvider



// Define the useAuth hook
export const useDatabase = () => {
  const useDatabaseContext = useContext(DatabaseContext);

  if (!useDatabaseContext) {
    throw new Error("useDatabase must be used within an DatabaseContextProvider");
  }

  return useDatabaseContext;
};

export {type IGroup }