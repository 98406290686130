import Dexie, { Table } from 'dexie';

export type IGroup = {
    id:string,
    name: string 
    description?:string
  }

  export type IStat = {
    id:string,
    hash: string,
    position: number,
    name: string,
    group: string,
    date: Date,
    draw_type: string, 
    draw_number: string, 
    draw_name: string,
    price: number
  }
  

export class ParserDatabase extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  group!: Table<IGroup>;
  stats!: Table<IStat>;

  constructor() {
    super('parser');
    this.version(1).stores({
      group: 'id, name, description', // Primary key and indexed props
      stats: 'id, hash, position, name, group, date, draw_type, draw_number, draw_name, price' // Primary key and indexed props
    });
  }
}

export const db = new ParserDatabase();
