"use client";

import React, { useEffect } from 'react';
import styled from "@emotion/styled";
import { Box, Container, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useRouter } from 'next/navigation'

import { UserButton } from '@clerk/nextjs';

import { useAuth } from '@clerk/nextjs';



type LayoutProps = {
    children?: React.ReactNode;
    back?: boolean
    callback?: () => Promise<void>
}

const LayoutContainer = styled(Container)({
    padding: "0px !important", minHeight: "100vh", maxWidth: "100vw", borderRadius: 5,  background: "rgba(0,0,0,1)"
  }) as typeof Container

const MainContainer = styled(Container)({
    padding: "0px !important",
    alignContent: "center", 
    alignItems:"center",
    display: "flex",
    flexWrap: "wrap",
    height: "100vh",
   flex: 1
}) as typeof Container

const LayoutChild = styled(Box)({
    padding: "1em"
  });

const TopBar = styled(Box)({
    display: "flex",
    justifyContent:"space-between"
  });

  const Link = styled(Box)({
    display: "flex",
    justifyContent:"space-between",
    "&:hover" : {
        cursor: "pointer",
        borderBottom: "1px solid #272727"
    }
  });


const Content = styled(Box)({
  margin: "1em",
  display: "flex",
  minHeight: "80vh"
});


const LayoutContent: React.FC<LayoutProps> =   (props) => {
    const {push} = useRouter()
     const signin = useAuth()

    return (
        <LayoutContainer maxWidth={false} >
                <LayoutChild>
                  
                {!!signin && !!signin.isSignedIn  && (
                    
                         <TopBar>
                                {!!props.back ? (
                                <Link onClick={ async () =>  {
                                            if(!!props.callback) {
                                                await props.callback()
                                            }
                                        push("/secure/dashboard") } 
                                        }>
                                        <KeyboardArrowLeftIcon />
                                        <Typography>Back</Typography>
                                    </Link>
                                ) :  (
                                    <Link></Link>
                                )}
                                <UserButton />
                         </TopBar>
                         )} 
                        <Content className="content-childrens">
                                {props.children}
                        </Content>

                </LayoutChild>
                
        </LayoutContainer>
    )
}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
   
    return (
<>
            <MainContainer maxWidth={false}>
                <LayoutContent {...props}/>
            </MainContainer>
        </>
    );
};

export default Layout;