"use client"
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import themes from "@/utils/themes";
import { Localization, enUS } from '@mui/material/locale';
import { ThemeProvider } from '@mui/material';
import { useRouter } from 'next/router';

export type ProviderProps = PropsWithChildren<{
  locale?: Localization
}>;


const ThemeConfig = ({ children, locale }: ProviderProps) => {
  if(!!!locale) {
    locale = enUS
  }

  return (
        <ThemeProvider  theme={themes(locale)} >
          <CssBaseline />
          {children}
        </ThemeProvider>
  );
}


interface GlobalContext {

}


// Create the AuthContext
const GlobalContext = React.createContext<GlobalContext | undefined>(undefined);
 
const GlobalProvider = (props: ProviderProps) => {
  const {push} = useRouter()

  const [init, setInit] = useState<boolean>(false)



  return (
    <GlobalContext.Provider
      value={{}} >
        <ThemeConfig>
          {props.children}
        </ThemeConfig>
    </GlobalContext.Provider>
  );
}
export default GlobalProvider



// Define the useAuth hook
export const useGlobal = () => {
  const useGlobalContext = useContext(GlobalContext);

  if (!useGlobalContext) {
    throw new Error("useDatabase must be used within an GlobalContextProvider");
  }

  return useGlobalContext;
};
