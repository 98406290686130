import { createTheme, ThemeProvider } from '@mui/material/styles';

import { frFR, Localization } from '@mui/material/locale';

const theme = (locale:Localization) => {
    return createTheme(
        {
          palette: {
            mode: "dark",
            primary: { main: '#000' },
            info: {
              main: 'rgba(255, 255, 255, 0.7)',
            },
          },
          components: {
            MuiButton : {
              defaultProps : {
                  fullWidth:  true
              },
              styleOverrides: {
                root: {
                  marginRight: "1em",
                  marginLeft: "1em"
                }
                
              }
              
            }
          }
        },
        locale,
      );

}



export default theme;